<template>
  <div class="works">
    <LeftMenu current='Income' />
    <div class="main">
      <el-row>
        <el-col :span="12">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item to="/income">我的收益</el-breadcrumb-item>
            <el-breadcrumb-item>确认账单</el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
        <el-col :span="12" style="text-align: right"
          ><el-link type="primary"  @click="$router.push('/incomeDetails')">
            收益明细</el-link
          ></el-col
        >
      </el-row>
      <el-divider></el-divider>
      <el-row>
        <el-row type="flex" class="row-bg" justify="space-between">
          <el-col :span="9"
            ><div class="grid-left">
              您有<span>{{ incomeListUnchecked.readyMoney||0|money }}</span
              >元收益待确认
            </div></el-col
          >
          <el-col :span="8"
            ><div class="grid-right">
              <div>已确认收益{{doneMoney|money}}￥</div>
              <div style="cursor: pointer;" @click="$router.push('/incomeList')">去申请提现 <i class="el-icon-right"></i></div>
            </div>
          </el-col>
        </el-row>
      </el-row>
      <div class="table">
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="headerStyle"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column label="账单名称" prop="title"></el-table-column>
          <el-table-column :formatter="getOrderTypeById" prop="type" label="账单分类" width="120">
          </el-table-column>
          <el-table-column
            prop="paymentCompany"
            label="付款方"
            width="260"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="totalMoney"
            label="金额"
            width="100"
            show-overflow-tooltip
          >
          <template slot-scope="scope">
                 {{scope.row.totalMoney|money}}
          </template>
          </el-table-column>
          <el-table-column>
            <template slot-scope="scope">
              <el-link
                type="danger"
                :underline="false"
                @click="$router.push({ path: '/billdetails',query:{id:scope.row.id}})"
                >详情</el-link
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="foot-but">
          <div class="left">
            <el-checkbox v-model="checkList" @input="selectall"
              >全选</el-checkbox
            >
          </div>
          <div class="right">
            <span
              >合计:<i>￥</i><i>{{ num|money }}</i></span
            >
            <el-button :disabled="multipleSelection.length===0"  @click="centerDialogVisible=true">确认账单</el-button>
          </div>
        </div>
      </div>
    </div>
       <!-- 提示框 -->
    <el-dialog
      title="提示"
      :visible.sync="centerDialogVisible"
      width="500px"
      center
      :close-on-click-modal="false"
    >
      <span>账单一经确认不可撤回，请确认账单是否无误</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="info" @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="alldefine">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import LeftMenu from '../components/LeftMenu.vue'
import { wallet_incomeList, wallet_incomeDetailCheck } from '../api/index'
import wallet from "@/api/wallet"
export default {
  components: {
    LeftMenu
  },
  created () {
    this.incomeList()
    // 已确认收益
    wallet.detail().then(res=>{
    this.doneMoney  = res.cashOutData.doneMoney
    })
  },

  data () {
    return {
      headerStyle: {
        background: '#f8f8f8',
        fonSsize: '14px',
        fontFamily: 'PingFang SC, PingFang SC - Regular',
        fontWeight: 400,
        color: '#333333'
      },
      tableData: [],
      multipleSelection: [],
      //  全选
      checkList: false,
      // 总计
      num: 0,
      incomeListUnchecked: {},
    //  可提现收益
    doneMoney:0,
    centerDialogVisible:false,
    };
  },
  methods: {
    incomeList () {
      wallet_incomeList().then(res => {
        this.tableData = res.data.list
        this.incomeListUnchecked = res.data
      })
    },

    handleSelectionChange (val) {
      this.multipleSelection = val;
      if (
        this.tableData.length == val.length
      ) {
        this.checkList = true;
      }
      if (this.tableData.length > val.length) {
        this.checkList = false;
      }
      this.num = 0
      val.forEach((item) => {
        this.num += Number(item.totalMoney);
      })
    },
    selectall () {
      if (this.checkList) {
        this.$refs.multipleTable.clearSelection();
        this.tableData.forEach((item) => {
          this.$refs.multipleTable.toggleRowSelection(item);
        })
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    getincomeDetailCheck (val) {
      let p = val
      wallet_incomeDetailCheck(p).then(res => {
        if (res.code == 0) {
          this.incomeList()
           this.$router.push('/billdetailsuccess')
        }

      })
    },
    //确认
    alldefine () {
    this.centerDialogVisible=false;
    this.checkList=false
      let arrids = this.multipleSelection.map(item => {
        return item.id
      })
      this.getincomeDetailCheck(arrids.join(","))
    },
      getOrderTypeById (_, __, id) {
      // 账单类型（后台字典类型10016）：1001601账单分成 1001602版税分成 1001603词曲收入 1001604其他。以后新增和编辑维护
      switch (id) {
        case '1001601': return '账单分成'
        case '1001602': return '版税分成'
        case '1001603': return '词曲收入'
        case '1001604': return '其他'
        default: return '其他'
      }
    }
  },
  filters:{
   money(val){
       return val.toFixed(2)
   }
  }
}
</script>

<style scoped lang="scss">
@import "../styles/initialization.scss";
@import "../styles/works.scss";
.el-divider {
  margin-top: 12px;
  margin-bottom: 40px;
}
.grid-left {
  width: 292px;
  //   border: 1px solid;
  font-size: 24px;
  font-family: PingFang SC, PingFang SC-Medium;
  font-weight: 500;
  text-align: LEFT;
  color: #333333;
  letter-spacing: 0px;
   white-space: nowrap;
  span {
    color: rgba(255, 71, 126, 1);

  }
}
.grid-right {
  //   border: 1px solid;
  width: 280px;
  height: 44px;
  box-sizing: border-box;
  background: #f9f9f9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  div:nth-child(1) {
    font-size: 14px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: LEFT;
    color: #333333;
    letter-spacing: 0px;
  }
  div:nth-child(2) {
    font-size: 14px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: LEFT;
    color: #ff004d;
    letter-spacing: 0px;
  }
}
::v-deep .table {
  margin-top: 30px;
  .el-table__header-wrapper .el-checkbox {
    display: none;
  }
  .el-checkbox__inner {
    border-radius: 50%;
  }
  .el-table__row {
    font-size: 14px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: LEFT;
    color: #666666;
  }
  .foot-but {
    height: 44px;
    background: #f9f9f9;
    box-sizing: border-box;
    padding-left: 16px;
    display: flex;
    justify-content: space-between;
    .left {
      .el-checkbox__label {
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: LEFT;
        color: #666666;
      }
      line-height: 44px;
    }
    .right {
      span {
        i {
          font-style: normal;
          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Medium;
          font-weight: 500;
          text-align: LEFT;
          color: #ff004d;
          line-height: 14px;
        }
        i:nth-child(2) {
          font-size: 24px;
        }
      }
      button {
        margin-left: 14px;
        width: 110px;
        height: 44px;
        opacity: 1;
        background: #ff004d;
        border: none;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        color: #ffffff;
      }
    }
  }
}
::v-deep .el-link--inner {
  margin: 0px 5px;
}
::v-deep .el-dialog {
  .el-dialog__header {
    font-size: 18px;
    font-family: PingFang SC, PingFang SC-Semibold;
    font-weight: 600;
    color: #222222;
    letter-spacing: 0px;
    padding-top: 40px;
    padding-bottom: 0px;
  }

  .el-dialog__body {
    text-align: center;
    padding-top: 60px;
    padding-bottom: 48px;
    font-size: 16px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: CENTER;
    color: #222222;
    letter-spacing: 0px;
  }
  .el-dialog__footer {
    padding-bottom: 60px;
    padding-top: 0px;
    .el-button {
      width: 160px;
      height: 44px;
      margin: 0px 20px;
    }
  }
}
</style>
